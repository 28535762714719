<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title>New Lead</h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <ng-container *ngIf="message.type">
      <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
        <p>{{ message.text }}</p>
      </app-sms-notice-banner>
    </ng-container>

    <form [formGroup]="addLeadForm" autocomplete="off">
      <div class="flex flex-row flex-wrap justify-between gap-4">
        <mat-form-field class="grow">
          <mat-label>First Name</mat-label>
          <input matInput required formControlName="fname" [maxlength]="25" required [appInputMaxLength] />
          <mat-hint *ngIf="addLeadForm.value.fname.length == 25" class="error-msg">Maximum length is 25 characters</mat-hint>
          <mat-error *ngIf="checkError('fname', 'required')">First name is a required field.</mat-error>
        </mat-form-field>

        <mat-form-field class="grow">
          <mat-label>Last Name</mat-label>
          <input matInput required formControlName="lname" [maxlength]="25" [appInputMaxLength] />
          <mat-hint *ngIf="addLeadForm.value.lname.length == 25" class="error-msg">Maximum length is 25 characters</mat-hint>
          <mat-error *ngIf="checkError('lname', 'required')">Last name is a required field.</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isb2bPartner" class="flex flex-row flex-wrap justify-between gap-4">
        <mat-form-field class="grow">
          <mat-label>Client/Partner/Affiliate</mat-label>
          <mat-select required formControlName="b2bpartner_id" (selectionChange)="b2bChange($event.value)">
            <mat-option *ngFor="let b2b of b2bPartner_data" value="{{b2b.b2bpartner_id}}" [innerHTML]="b2b.company"></mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('b2bpartner_id', 'required')">Client/Partner/Affiliate is a required field.</mat-error>
        </mat-form-field>

        <mat-form-field class="grow">
          <mat-label>Tracking Code</mat-label>
          <mat-select required formControlName="source_code">
            <ng-container *ngFor="let tCode of tracking_data">
              <mat-option *ngIf="tCode.active == '1'" value="{{tCode.tracking_code}}" [innerHTML]="tCode.tracking_code"></mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="checkError('source_code', 'required')">Tracking Code is a required field.</mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row flex-wrap justify-between gap-4">
        <mat-form-field class="grow-0 w-1/6">
          <mat-select formControlName="phone_type">
            <mat-option *ngFor="let phoneType of phoneTypes" [value]="phoneType.value">{{ phoneType.id }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="grow">
          <input matInput placeholder="Telephone #" formControlName="phone" appPhoneNumberMask [minlength]="14">
          <mat-error *ngIf="addLeadForm.value.phone.length != 14">Please enter a valid phone number</mat-error>
        </mat-form-field>

        <mat-form-field class="grow-0 w-1/6">
          <mat-select formControlName="email_type">
            <mat-option *ngFor="let emailType of emailTypes" [value]="emailType.value">{{ emailType.id }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="grow">
          <input matInput placeholder="Email" formControlName="email"[appInputMaxLength] />
          <mat-error *ngIf="checkError('email', 'pattern')">Please enter a valid email address</mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row flex-wrap justify-between gap-4">
        <mat-form-field class="grow">
          <mat-label>Birthdate</mat-label>
          <input matInput formControlName="dob" [max]="maxDate" appDate>
          <mat-error *ngIf="checkError('dob', 'invalidDate')">You must enter a valid date.</mat-error>
        </mat-form-field>

        <mat-form-field class="grow">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option></mat-option>
            <mat-option value="M">Male</mat-option>
            <mat-option value="F">Female</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex flex-row flex-wrap justify-between gap-4">
        <mat-form-field class="grow">
          <mat-label>Zip Code</mat-label>
          <input matInput formControlName="zip" [minlength]="5" [maxlength]="5" appNumbersOnly (keyup)="getCounties()"/>
        </mat-form-field>
        <mat-form-field class="grow">
          <mat-label>County</mat-label>
          <mat-select formControlName="county">
            <mat-optgroup *ngFor="let state of statesData; let i=index" label="{{state.state_name}}">
              <mat-option *ngFor="let county of state.counties; let ii=index" (click)="changeOption(state)" [value]="county?.county_name"> {{county?.county_name}} </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="grow">
          <mat-label>State</mat-label>
          <mat-select formControlName="state_id" (selectionChange)="changeState($event)">
            <mat-option></mat-option>
            <mat-option *ngFor="let state of states" [value]="state?.abbreviation">{{state?.abbreviation}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div class="w-full">
        <p class="font-bold">Notes</p>
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
        <ngx-editor [editor]="editor" formControlName="notes"></ngx-editor>
      </div>
    </form>

  </div>

  <div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-flat-button *ngIf="addLeadForm.valid" [disabled]="isButtonClicked" (click)="addLead('simple')">Add</button>
    <button mat-flat-button *ngIf="addLeadForm.valid" [disabled]="isButtonClicked" (click)="addLead('details')">Add + Details</button>
  </div>

</div>
